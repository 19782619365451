import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import Header from "../components/shared/Header";
import Posts from "../components/eBookList/Posts";
import PageNavigation from "../components/shared/PageNavigation";
import Seo from "../components/shared/seo";
import Grid from "../styles/Grid";
import breakpoints from "../styles/breakpoints";
import BlogSubscriptionBanner from "../components/shared/BlogSubscriptionBanner";
import routes from "../routes";

const Container = styled(Grid)`
  padding-top: 40px;

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 80px;
  }
`;

class EBookList extends React.Component {
  // componentDidMount() {
  //   window.analytics.page("eBooks");
  // }

  render() {
    const {
      location,
      pageContext: { locale, totalPages, currentPage },
      data,
    } = this.props;
    const eBookListData = data.prismicEbookListPage.data;
    const metaImage =
      data.allPrismicEbook.edges.length > 0 &&
      data.allPrismicEbook.edges[0].node.data.cover_image.url;

    return (
      <Layout location={location} locale={locale}>
        <Seo
          title={`${eBookListData.title} | Workmate`}
          description={eBookListData.brief}
          image={metaImage}
        />
        <Container>
          <Header title={eBookListData.title} brief={eBookListData.brief} />
          <Posts posts={data.allPrismicEbook.edges} />
          <PageNavigation
            currentPage={currentPage}
            totalPages={totalPages}
            route={routes.eBooks}
          />
        </Container>
        <BlogSubscriptionBanner
          title={data.prismicBlogListPage.data.cta_banner_title}
          description={data.prismicBlogListPage.data.cta_banner_description}
          hubspotFormId={data.prismicBlogListPage.data.hubspot_form_id}
        />
      </Layout>
    );
  }
}

export default EBookList;

export const eBookListQuery = graphql`
  query eBookListQuery($localeKey: String, $skip: Int!, $limit: Int!) {
    allPrismicEbook(
      limit: $limit
      skip: $skip
      filter: { lang: { eq: $localeKey } }
    ) {
      edges {
        node {
          uid
          lang
          data {
            cover_image {
              alt
              gatsbyImageData
            }
            title
            description
          }
        }
      }
    }
    prismicEbookListPage(lang: { eq: $localeKey }) {
      data {
        title
        brief
      }
    }
    prismicBlogListPage(lang: { eq: $localeKey }) {
      data {
        cta_banner_title
        cta_banner_description
        hubspot_form_id
      }
    }
  }
`;
