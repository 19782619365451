import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import breakpoints from "../../styles/breakpoints";
import { fontBodyRegular, fontH5 } from "../../styles/fonts";
import routes from "../../routes";
import SmartLink from "../shared/SmartLink";
import EmptyList from "../shared/EmptyList";
import colors from "../../styles/colors";

const Image = styled.div`
  :hover {
    border: 1px solid ${colors.border};
  }
`;

const Description = styled.div`
  ${fontBodyRegular};
  overflow: hidden;
`;

const Title = styled.div`
  ${fontH5};
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Post = styled.div`
  margin-bottom: 60px;

  ${Image} {
    margin-bottom: 21px;
    padding: 34px 24px;
  }

  ${Title} {
    margin-top: 16px;
    margin-bottom: 18px;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Image} {
      margin-bottom: 30px;
      padding: 36px 28px;
    }

    ${Title} {
      margin-top: 16px;
      margin-bottom: 21px;
    }
  }
`;

const PostsWrapper = styled.div``;

const Container = styled.div`
  margin-top: 50px;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;

    ${PostsWrapper} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 32px;
    }
  }
`;

const Posts = ({ posts }) => {
  if (posts.length === 0) return <EmptyList />;
  return (
    <Container>
      <PostsWrapper>
        <>
          {posts.map(({ node }) => {
            const { cover_image, title, description } = node.data;
            const coverImageData = getImage(cover_image);
            return (
              <Post key={node.uid}>
                {coverImageData && (
                  <Image>
                    <SmartLink to={routes.eBooks + "/" + node.uid}>
                      <GatsbyImage
                        image={coverImageData}
                        alt={cover_image.alt}
                      />
                    </SmartLink>
                  </Image>
                )}
                <Title>
                  <SmartLink to={routes.eBooks + "/" + node.uid}>
                    {title}
                  </SmartLink>
                </Title>
                <Description>{description}</Description>
              </Post>
            );
          })}
        </>
      </PostsWrapper>
    </Container>
  );
};

export default Posts;
